
/*
* ----------------------------------------------------------------------------------------
*  START OURTEAM 2 STYLE
* ----------------------------------------------------------------------------------------
*/
.our-team{
    border: 1px solid #cea676;
    border-radius: 30px;
    text-align: center;
    margin-bottom: 30px;
    overflow: hidden;
}
.our-team .team_img{
    position: relative;
    overflow: hidden;
}
.our-team .team_img:after{
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.2);
    position: absolute;
    bottom: -100%;
    left: 0;
    transition: all 0.3s ease 0s;
}
.our-team:hover .team_img:after{
    bottom: 0;
}
.our-team img{
    width: 100%;
    height: auto;
}
.our-team .social{
    padding: 10px 0 10px 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: -100%;
    right: 30px;
    background: #cea676;
    border-radius: 0 0 20px 20px;
    z-index: 1;
    transition: all 0.3s ease 0s;
}
.our-team:hover .social{
    top: 0;
}
.our-team .social li a{
    display: block;
    padding: 10px 15px;
    font-size: 15px;
    color: #fff;
}
.our-team:hover .social li a:hover{
    color: #000;
}
.our-team .team-content{
    padding: 20px 0;
    background: #fff;
}
.our-team .title{
    margin: 0 0 25px 0;
    font: 600 16px 'Open Sans',sans-serif;
    color: #000;
    text-transform: capitalize;
    margin: 0 0 20px;
    position: relative;
}
.our-team .title:before{
    content: "";
    width: 25px;
    height: 1px;
    background: #cea676;
    position: absolute;
    bottom: -10px;
    right: 50%;
    margin-right: 9px;
    transition-duration: 0.25s;
}
.our-team .title:after{
    content: "";
    width: 25px;
    height: 1px;
    background: #cea676;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: 9px;
    transition-duration: 0.25s;
}
.our-team:hover .title:before,
.our-team:hover .title:after{
    width: 50px;
}
.our-team .post{
    display: inline-block;
    font: 500 14px 'Open Sans',sans-serif;
    color: #666;
    text-transform: capitalize;
}
.our-team .post:before{
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #cea676;
    margin: 0 auto;
    position: relative;
    top: -13px;
}
.our-team.ico-team{
    border: 1px solid #5128ff;
}
.our-team.ico-team .social{
    background: #ff6699
}
.our-team.ico-team .team-content{
    padding: 20px 0 30px
}
.our-partners .team-member {
    border: 2px solid #eaeaea;
}
.our-partners .team-member {
    display: inline-block;
    padding: 10px;
    width: 100%;
    background: #fff;
    text-align: center;
    margin: 0 0 30px 0;
    transition: all .4s ease-in-out;
    border: 2px solid #eaeaea;
}
.our-partners .team-member:hover {
    cursor: pointer;
    border-color: #1d66f6;
}
.half{
    width: 80px;
    height: 2px;
    background: #FFC107;
}
.who-we-contant.new{
    margin-right: -100px;
    padding: 50px;
    position: relative;
    padding-right: 100px;
    border: 10px solid #ba97f5;
    border-right: 0;
}
@media (max-width: 992px){
    .who-we-contant.new{
        margin-right: 0;
        padding-right: 50px
    }
}
.who-we-contant.new:before{
    content: '';
    position: absolute;
    width: 10px;
    height: 30%;
    top: 0;
    right: 0;
    background: #ba97f5
}
.seperator{
    width: 5px;
    background: #FFC107;
    text-align: center;
    height: 60%;
    margin: 0 auto
}
.icon-icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    line-height: 74px;
    text-align: center;
    border-radius: 50%
}
.icon-icon img{
    max-width: 45px
}
.icon-icon.bg1{
    background-image: linear-gradient(-12deg,#FF895B 0,#FFEC9E 100%);
}
.icon-icon.bg2{
    background-image: linear-gradient(-151deg,#FCD3BB 0,#F88691 94%);
}
.icon-icon.bg3{
    background-image: linear-gradient(145deg,#A2F8E4 0,#4BC39D 100%);
}
.icon-icon.bg4{
    background-image: linear-gradient(-12deg,#2a57d7 0,#9eeeff 100%);
}


/* ##### 13.0 Team Area CSS ##### */

.single-team-member {
    position: relative;
    z-index: 1;
    padding: 0 30px;
    margin-bottom: 100px;
}
.single-team-member.hos{
    padding: 0 !important;
}
.team-member-thumb {
    width: 190px;
    height: 190px;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
    margin:0 auto;
    margin-bottom: 20px;
}
.team-member-thumb.hos {
    overflow: hidden;
    width: auto;
    height: auto;
    border: 1px solid #eee;
    background: #fff;
    padding-top: 10px;
    border-bottom: 0;
    border-radius: 0;
    position: relative;
    margin: 0 auto;
    margin-bottom: 0px;
    transition: all .4s ease-in-out;
}
.team-member-thumb.hos:hover{
    border-color: #5785e8
}
.team-info h5 {
    color: #7057f9;
    text-transform: uppercase;
    font-size: 18px;
    text-align: center;
    margin-bottom: 0px;
}

.team-info p {
    color: #a592b4;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 0;
    text-align: center;
}
.team-social-icon{
    text-align: center;
    position: absolute;
    top: 20px;
    right: 4%;
}
.team-social-icon a {
    font-size: 22px;
    margin-top: 10px;
    width: 80px;
    height: 80px;
    /*background: url(../img/svg/diamond-shape.svg) no-repeat center center;*/
    background-size: contain;
    line-height: 70px;
    display: inline-block;
}