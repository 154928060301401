
/* ##### 3.0 Wellcome Area CSS ##### */
.hero-section{
    position: relative;
    min-height: 750px;
    /*background: url('../../../assets/img/bg-img/header2.jpg') no-repeat bottom center;*/
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-section.app{
    position: relative;
    min-height: 700px;
    /*background: url('../../../assets/img/bg-img/header-app.png') no-repeat bottom center;*/
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-section.app img{
    width: 100%
}
.hero-section.ico-header5{
    background: url(../../../assets/img/bg-img/header-bg5.png) no-repeat bottom left;
    background-size: cover
}
.hero-section.curved-section:before{
    content: '';
    position: absolute;
    width: 103%;
    height: 100%;
    left: 0px;
    right: 0px;
    bottom: -3px;
    /*background: url(../../../assets/img/svg/curve.svg) no-repeat bottom;*/
    z-index: 0
}
.hero-section.curved-section .welcome-content{
    margin-top: 0
}
.hero-section.app2{
    position: relative;
    min-height: 100vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.app2{
    /*background: url(../../../assets/img/bg-img/hero-app-bg.png) no-repeat center, linear-gradient(90deg, #5f36ff 0, #b719f6 100%);*/
}
.app3{
    /*background: url(../../../assets/img/bg-img/app2-bg.png) no-repeat center;*/
    background-size: cover;
}
.agency-bg{
    /*background: url(../../../assets/img/bg-img/agency-bg.jpg) no-repeat center;*/
    background-size: cover
}
.creamy{
    position: absolute;
    width: 500px;
    height: 500px;
    top: 10%;
    right: 50%;
    -webkit-animation: floating3 7s infinite;
    -o-animation: floating3 7s infinite ;
    animation: floating3 7s infinite ;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: linear-gradient(90deg, #5f36ff 0, #b719f6 100%)
}
@keyframes floating3 {
    0% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translate(0px, 0px) scale(1);
    }
    25% {
        transform: rotateX(0deg) translate(10px, 190px) scale(1.3);
    }
    50% {
        transform: rotateX(0deg) translate(190px, 230px) scale(1);
    }
    75% {
        transform: rotateX(0deg) translate(170px, 190px) scale(1.3);
    }
    100% {
        -webkit-transform: rotateX(0deg) translateY(0px);
        -moz-transform: rotateX(0deg) translateY(0px);
        -ms-transform: rotateX(0deg) translateY(0px);
        -o-transform: rotateX(0deg) translateY(0px);
        transform: rotateX(0deg) translate(0px, 0px) scale(1);
    }
}
.cd-intro.v2 {
    margin: 0em auto;
}
.cd-intro.v2 .cd-headline.clip span{
    display: inline;
    padding: 0 !important
}
.cd-intro.v2 .cd-headline.clip .cd-words-wrapper {
    vertical-align: middle;
}
#canvasplus {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .6;
    z-index: 0
}
.hero-section.fullwidth-header{
    padding-top: 100px;
    padding-bottom: 405px;
    background-image: url(../../../assets/img/bg-img/header-bg3.png);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}
.hero-section.fullwidth-header .special-head.dark{
    padding-left: 0
}
.hero-section.fullwidth-header .special-head.dark:before{
    display: none;
}
.city-img{
    position: relative;
    bottom: 0px
}
@media (max-width: 991px){
    .hero-section.fullwidth-header{
        padding-bottom: 0px;
        background-image: url(../../../assets/img/bg-img/header-bg3.png);
        background-position: top center;
        background-size: cover;
        padding-top: 0
    }
    .special-pb{
        padding-bottom: 100px
    }
    .welcome-content.spe{
        margin-top: 0 !important
    }
}
.hero-section.gradient{
    overflow: visible;
    background-image: linear-gradient(106deg,#d787f5,#3634bb);
}

@media (min-width: 767px ){
    .hero-section.curved-section{
        padding-top: 150px
    }
}
@media (min-width: 992px ){
    .hero-section.curved-section{
        padding-top: 100px
    }
}
@media (max-width: 767px){
    .hero-section.curved-section img.curved{
        display: none;
    }
}
@media (max-width: 1200px) and (min-width: 992px){
    .fullscreen-bg{
        margin-top: -400px;
        margin-left: -50px
    }
}
.hero-section.curved-section .special-head{
    padding-left: 0
}
.hero-section.curved-section .special-head:before{
    display: none;
}
.welcome_area {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 900px !important;
}

.hero-content {
    width: 100%;
    height: 100%;
    background: rgba(72, 52, 212, 0.95);
    background: -webkit-linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: -webkit-linear-gradient(left, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.95));
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: 10;
}
.hero-content.globe{
    background: linear-gradient(to right, rgba(72, 52, 212, 0.95), rgba(52, 31, 151, 0.75));
}
.hero-content.with-after{
    background: url('../../../assets/img/svg/bg_hero.svg') no-repeat center right;
}
.hero-content.with-after-before{
    background-image: url(../../../assets/img/svg/bg_hero1.svg),url(../../../assets/img/svg/bg_hero2.svg);
    background-position: right top,left bottom;
    background-size: auto;
    background-repeat: no-repeat;
}
.hero-content.soft1{
    /*background: url(../../../assets/img/bg-img/soft1.png) no-repeat top right;*/
    background-size: 65% 100%;
}
.hero-section.soft2{
    /*background: url(../../../assets/img/bg-img/soft2.jpg) no-repeat bottom center;*/
    background-size: cover;
}
@media (max-width: 992px){
    .hero-content.soft1{
        background-size: 100%
    }
}
.hero-content.creative{
    /*background: url('../../../assets/img/bg-img/header3.png') no-repeat center right;*/
    background-size: cover
}
.hero-content.pizza {
    /*background: url(../../../assets/img/bg-img/banner-bg.jpg) no-repeat right top;*/
}
.hero-section.fuel {
    /*background: url(../../../assets/img/team-img/ico/header-bg3.png) no-repeat right bottom;*/
    background-size: cover;
    padding-top: 60px;
    padding-bottom: 765px;
}
.hero-section.corporate-bg-1{
    /*background: url(../../../assets/img/bg-img/corporate-bg-1.jpg) no-repeat center center;*/
    background-size: cover;
}
.hero-section.hos-bg-2{
    /*background: url(../../../assets/img/bg-img/hos-bg-2.jpg) no-repeat center top;*/
    background-size: cover;
}

.hero-section.blue-bg{
    background: #2e39bf;
    overflow: hidden;
}
.hero-section.blue-bg:before{
    height: 700px;
    content: '';
    width: 150%;
    left: 0;
    bottom: -324px;
    background: rgba(37,46,176,1);
    display: inline-block;
    position: absolute;
    transform: rotate(155deg);
}
.hero-content.tringle{
    /*background: url('../../../assets/img/bg-img/tringle.png') no-repeat center right;*/
}
.hero-content.scew{
    /*background: url('../../../assets/img/bg-img/header2.png') no-repeat center top;*/
}
.hero-content.trans{
    /*background: url('../../../assets/img/bg-img/trans.png') no-repeat right top;*/
}
.hero-content.transparent{
    background: transparent;
}
.hero-content.dark-blue{
    background: rgb(33, 0, 67, 0.9);
}
.hero-content .ico-counter{
    margin: 100px 7% 0;
}
@media (max-width: 992px){
    .hero-content .ico-counter,
    .hero-content .service-img-wrapper .image-box{
        margin-top: 0px !important
    }

}
.hero-content .service-img-wrapper .image-box .rings{
    position: absolute;
    top: 50%;
    z-index: -1;
    margin: 0 auto;
    left: 50% !important;
    width: 120%;
    transform: translate(-50%, -50%);
    animation: unset;
    -webkit-animation: unset;
}
.welcome-content {
    position: relative;
    z-index: 1;
    margin-top: 90px;
}

.promo-section {
    margin-bottom: 30px;
}
.promo-section.bg{
    background: #ff966a;
    padding: 10px 20px;
    display: inline-block;

}
.promo-section.bg2{
    background: #cb202d;
    padding: 10px 20px;
    display: inline-block;

}

.promo-section.bg .special-head{
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 1px;
}
.subscribe-section{
    background: #fff;
    padding: 15px;
    display: flex;
    border-radius: 35px !important
}
.subscribe-section .input-wrapper{
    position: relative;
    display: inline-block;
    width: 70%
}
.subscribe-section input{
    padding: 10px 10px 10px 40px;
    position: relative;
    border: 1px solid #eee;
    width: 100%;
    border-radius: 25px;
}
.subscribe-section input:focus{
    border-radius: 25px;
    outline: none;
    border-color: #2ec8a6
}
.subscribe-section i{
    font-size: 20px;
    color: #fc9936;
    position: absolute;
    z-index: 1;
    top: 13px;
    left: 15px
}
@media (max-width: 767px){
    .subscribe-section{
        display: inline-block;
    }
    .subscribe-section .input-wrapper{
        width: 100%
    }
    .subscribe-section .more-btn{
        margin-left: 0 
    }
}

@media (min-width: 1200px){
    .ico-circled{
        width: 400px;
        height: 400px;
        position: absolute;
        top: 0;
        left: 0;
        border: 70px solid #2bd6ff;
        border-radius: 50%;
        opacity: 0.2;
        animation: floating2 7s infinite;
            -webkit-animation: floating2 7s infinite;
    }
}

.special-head{
    color: #28cc8b;
    padding-left: 40px;
    font-size: 18px;
    position: relative;
}
.special-head:before{
    content: '';
    background: #05ffff ;
    position: absolute;
    top: 50%;
    left: 0;
    width: 30px;
    height: 2px;
}
.special-head.dark{
    color: #f8f517;
    font-weight: bold;
}
.special-head.dark:before{
    background: #fff
}
.integration-link {
    padding: 8px 10px;
    display: inline-block;
    vertical-align: top;
    position: relative;
    letter-spacing: .58px;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    color: #d5d5ea
}

.integration-link:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background : #eee;
    border-radius: 100px;
}
.integration-icon {
    margin: 0 5px 0 0;
}
.integration-icon .badge{
    background: #14cab1;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border-radius: 100px;
    padding: 6px 13px;
}
.integration-text {
    margin: 0 7px 0 0;
    color: #7e7b7b
}
.integration-link>* {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: top;
}

.main-ilustration{
    position: relative;
    /*background: url(../../../assets/img/core-img/hero-bg.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: contain;
}
.main-ilustration-2{
    position: relative;
    /*background: url(../../../assets/img/core-img/robot-1.png) no-repeat center bottom;*/
    height: 100vh;
    bottom: 0;
    background-size: contain;
}
.main-ilustration-3{
    position: relative;
    /*background: url(../../../assets/img/core-img/robot-2.png) no-repeat center bottom;*/
    height: 100vh;
    bottom: 0;
    background-size: cover;
}
.main-ilustration-4{
    position: relative;
    /*background: url(../../../assets/img/svg/head-bg-1.svg) no-repeat center 65%;*/
    height: 100vh;
    background-size: cover;
}
.main-ilustration-5{
    position: relative;
    /*background: url(../../../assets/img/core-img/about-5.png) no-repeat center 65%;*/
    height: 75vh;
    background-size: 100%;
}
.header-ilustration-1{
    min-width: 0px;
    margin-top: 60px;
    position: relative;
}
.header-ilustration-1 img{
    width: 100%;
    border-radius: 10px
}
.header-ilustration-1.small img{
    width: 85%
}
.header-ilustration-1.big img{
    width: 105%
}

.has-shadow {
    -webkit-box-shadow: 0 9px 68px 0 rgba(62,57,107,.2);
    box-shadow: 0 9px 68px 0 rgba(62,57,107,.2);
    border-radius: 10px
}
.overlay:after{
    background: rgba(0,0,0,.3);
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
}
.overlay.v2:after{
    background: rgba(255, 255, 255, 0.95)
}
@media (min-width: 991px){
    .circle-m:before{
        /*background: url(../../../assets/img/core-img/circle-m.png) no-repeat center center;*/
        background-size: contain;
        width: 250px;
        height: 250px;
        content: '';
        position: absolute;
        animation: floating2 7s infinite;
        -webkit-animation: floating2 7s infinite;
        top: -50px;
        left: -50px;
    }
    .cir-left:before{
        background: url(../../../assets/img/core-img/cir-left.png) no-repeat center center;
        background-size: contain;
        width: 300px;
        height: 750px;
        content: '';
        position: absolute;
        opacity: 0.5;
        top: 0px;
        left: 0px;
    }
    .cir-right:before{
        background: url(../../../assets/img/core-img/cir-right.png) no-repeat center center;
        background-size: contain;
        width: 300px;
        height: 750px;
        content: '';
        position: absolute;
        opacity: 0.5;
        top: 0px;
        right: 0px;
    }

}
.header-ilustration-1 .video-btn-container{
    position: absolute;
    width: 150px;
    height: 150px;
    /*background: url(../../../assets/img/core-img/video-btn-bg.png) no-repeat center center;*/
    background-size: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.header-ilustration-1 .video-btn-container .video-icon a{
    background: #fff;
    color: #2ea9fd;
}
.bub-right{
    background: url('../../../assets/img/svg/bg_hero.svg') no-repeat center right;
}

.bub-left{
    /*background: url('../../../assets/img/svg/benefits-bg.svg') no-repeat center left;*/
}
.welcome-meter.bg:before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../../assets/img/svg/bg-home-bubble.svg) center;
    background-size: cover;
    overflow: visible;
}
@media (max-width: 992px){
    .header-ilustration-1{
        margin-top: 0
    }
    .bub-right{
        background-size: cover;
    }
    .bub-left{
        background-size: cover;
    }

}

.main-ilustration-6{
    position: relative;
    /*background: url(../../../assets/img/core-img/about-1.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 100%;
}
.main-ilustration-7{
    position: relative;
    /*background: url(../../../assets/img/core-img/faq.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 80%;
}
.main-ilustration-8{
    position: relative;
    background: url(../../../assets/img/svg/header-area-ill.svg) no-repeat center 65%;
    height: 100vh;
    background-size: 100%;
}
.main-ilustration-9{
    position: relative;
    /*background: url(../../../assets/img/core-img/travel.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 100%;
}
.main-ilustration-10{
    position: relative;
    /*background: url(../../../assets/img/core-img/pizza-header.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 80%;
}
.main-ilustration-11{
    position: relative;
    /*background: url(../../../assets/img/team-img/app/app3.png) no-repeat center 65%;*/
    height: 100vh;
    background-size: 100%;
}
@media (max-width: 992px){
    .main-ilustration-5{
        height: 65vh 
    }
    .mt-md-30{
        margin-top: 30px !important
    }
    .mb-md-30{
        margin-bottom: 30px
    }
}
@media (max-width: 1200px){
    .main-ilustration-3{
        background-size: contain;
        background-position: left;
    }
    .main-ilustration-3 .inovation{
        right: 0% !important;
        top: 5%;
        display: inline-table;
    }
    .main-ilustration-3 .inovation.num2{
        right: 5%;
        top: 27%;
    }
}

@media (max-width: 767px){
    .mt-sm-30{
        margin-top: 30px
    }
    .mt-sm-15{
        margin-top: 15px
    }
    .mt-sm-0{
        margin-top: 0px !important
    }
    .mb-sm-30{
        margin-bottom: 30px
    }
    .main-ilustration-6{
        height: 65vh 
    }
    .welcome-content{
        padding-right: 15px
    }
    .main-ilustration-3{
        background-size: contain;
        background-position: left;
    }
    .main-ilustration-3 .inovation{
        right: 0% !important;
        top: 42%;
        display: inline-table;
    }
    .main-ilustration-3 .inovation.num2{
        right: 5%;
        top: auto;
    }
    .welcome-small-wrapper{
        position: absolute;
        width: 38%;
    }
    .welcome-content-small{
        position: absolute;
        top: -30px;
    }
    .welcome-content.ill{
        margin-top: 120px !important
    }
    .header-ilustration-1 .video-btn-container{
        width: 150px;
        height: 150px
    }
}
@media (max-width: 480px){
    .main-ilustration-6,
    .header-ilustration-1{
       display: none;
    }
    .welcome-content {
        vertical-align: middle;
        margin-top: 100px !important

    }
    .main-ilustration-3{
        display: none;
    }
    .welcome-small-wrapper{
       display: none;
    }
    
}
.main-ilustration-2:before{
    content: '';
    position: absolute;
    width: 30%;
    height: 1000px;
    left: 50%;
    top: -30%;
    transform: rotate(21deg);
    background: rgba(0, 0, 128, 0.7);
}
.main-ilustration-3 .inovation{
    position: absolute;
    bottom: 10%;
    right: -15%;
    width: 40%;
    background: #0d003b;
    padding: 20px;
}
.inovation.num2{
    right: -57%;
}
.inovation h5{
    padding-left: 25px;
    position: relative;
}
.inovation h5:before{
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    width: 15px;
    height: 2px;
    background: #25cbd3;
}
.inovation p{
    font-size: 12px;
    margin-bottom: 0;
}

@media (min-width: 1200px){
    .main-ilustration {
        min-width: 650px;
    }
}

.welcome-content h2 {
    font-size: 52px;
    font-weight: 500;
    margin-bottom: 20px;
}
.welcome-content h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
}
@media (max-width: 767px){
    .welcome-content .cd-headline{
        min-height: 190px
    }
}
.agency-header{
    position: relative;
    padding-left: 50px;
}
.agency-header span{
    position: absolute;
    top: 17px;
    left: -25px;
    font-size: 48px;
    font-weight: bolder;
    text-transform: uppercase;
    transform: rotate(270deg);
}
.welcome-content h1.artficial{
    font-size: 38px
}
.welcome-content p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 30px;
} 
.gradient-text {
    font-weight: bold;
    display: inline-block;
    background-image: linear-gradient(135deg, #846FF4 0%, #F17674 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}
.gradient-text.blue {
    background-image: linear-gradient(35deg, #17EAD9 0%, #6078EA 100%);
}
.gradient-text.cyan{
    background-image: linear-gradient(135deg, cyan 0%, cyan 100%) !important
}
p.artficial{
    padding-left: 20px;
    border-left: 1px solid #fff;
    position: relative;
}
p.artficial:before{
    content: '';
    position: absolute;
    left: -5px;
    top: -8px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #fff
}
.welcome-content-small{
    margin-bottom: 50px
}
.welcome-content-small h4{
    margin-bottom: 20px
}
.welcome-content-small p.artficial{
    font-size: 12px
}
.welcome-video-area {
    position: relative;
    z-index: 1;
    margin-top: 90px;
}

.welcome-video-area .welcome-thumb {
    position: relative;
    z-index: 1;
    margin-top: 30px;
    border: 1px solid #25cbd3;
    border-radius: 15px;
    padding: 0px 30px 0;
}

.welcome-video-area .welcome-thumb img {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    position: relative;
    top: -30px
}

.video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 40px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 10;
    color: #4834d4;
}

.video-btn {
    background-color: #4834d4;
    width: 70px;
    height: 70px;
    color: #fff;
    border-radius: 50%;
    line-height: 70px;
    padding: 0;
    text-align: center;
    min-width: 70px;
    font-size: 24px;
}
.video-btn.v2{
    width: 50px;
    height: 50px;
    line-height: 50px;
    min-width: 50px;
    font-size: 16px;
}
.video-btn:hover {
    background-color: #4834d4;
    color: #fff;
}


.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

.fullscreen-bg__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}
.welcome_area.video .desc{
    max-width: 60%;
    margin: 0 auto
}
@media (max-width: 767px) {
  .fullscreen-bg {
    /*background: url('video/video-frame.PNG') center center / cover no-repeat;*/
  }
   .welcome_area.video .desc{
        max-width: 80%;
        margin: 0 auto
    }
  .fullscreen-bg__video {
    display: none;
  }
}
.video-bg-illution{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #010111;
    z-index: 50;
    opacity: .7;
}
/* Features veersion 2*/
.item-feat{
    border: 2px solid #ddd;
    position: relative;
    margin-bottom: 30px;
    transition: all 0.3s;
}
.item-feat:after {
    content: '';
    width: 40px;
    height: 16px;
    position: absolute;
    top: -5px;
    left: -5px;
    display: block;
    background: #fff;
}
.item-feat h3 {
    background: #fff;
    padding: 5px 0;
    padding-top: 10px;
    font-size: 22px;
    font-weight: 600;
    transform: translate(-10px, 0);
}
.item-feat span.number {
    font-size: 1.8rem;
    font-weight: 800;
    transition: all 0.3s;
}
.item-feat p {
    padding: 0 30px 30px 30px;
    margin-bottom: 0;
}
.item-feat:hover,
.item-feat.hover {
    border-color: #3f88fb;
}
.item-feat:hover span.number,
.item-feat.hover span.number{
    color: #3f88fb;
}
/* ##### Restaurant template ##### */
.food-img-wrapper,
.food-img img{
    transition: all 1.5s ease-in-out;
    overflow: hidden;
}
.food-img-wrapper:hover .food-img img{
    transform: scale(1.1);
}
.offer-box {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: flex-end;
    -ms-flex-align: center;
    align-items: flex-end;
}
.seafood-box{
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: flex-start;
    align-items: flex-start;
}
.offer-info{
    position: absolute;
    top: 30px;
    left: 30px;
}
.offer-info.v2{
    position: absolute;
    top: 30px;
    right: 30px;
    left: auto;
}
.offer-info h4{
    font-size: 40px;
    margin-bottom: 0;
    line-height: 1;
    font-weight: 500
}
.offer-info p{
    font-size: 52px;
    font-weight: 700;
    line-height: 1;
}
.food-img-wrapper .text-wrapper {
    width: 370px;
    background: #cb202d;
    padding: 3%;
    margin-right: 50px;
    overflow: hidden;
    height: auto;
}
.seafood-box .sea-text{
    width: 370px;
    margin-left: 50px;
    overflow: hidden;
    height: auto;
}
.offer-text{
    text-transform: uppercase;
    color: #fff;
    float: right;
}
.offer-text p{
    font-size: 50px;
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0;
    color: #fff
}
.uppercase{
    text-transform: uppercase;
}
.offer-text .text-big{
    font-size: 116px;
    margin-bottom: 0;
    color: #fff;
    margin-top: 15px
}
@media (min-width: 450px){
    .food-img-wrapper .text-wrapper .percent {
        letter-spacing: -15px;
        font-size: 200px;
        line-height: 1;
        font-weight: bold;
        color: #fff;
    }
}